export const useFaqs = () => {
  const storyblokApiOptions = useStoryblokApiOptions();
  const storyblokApi = useStoryblokApi();
  const { localeStoryName } = useUtils();
  const storyblokStore = useStoryblokStore();

  const { locale } = useI18n();
  const { $store } = useNuxtApp();

  async function all() {
    const { data } = await storyblokApi.get("cdn/stories", {
      ...storyblokApiOptions,
      starts_with: "faqs/",
      is_startpage: 0,
      language: locale.value,
    });

    storyblokStore.faqs = data.stories;

    return storyblokStore.faqs
      .filter(
        (story) =>
          locale.value === "de" ||
          story.translated_slugs.find(
            (translation) => translation.lang === story.lang,
          ),
      )
      .map((story) => localeStoryName(story));
  }

  async function find(uuid: string) {
    const storyblokLinks = computed(() => $store.getters.getLinks);
    const link = storyblokLinks.value[uuid];

    const { data } = await storyblokApi.get(`cdn/stories/${link.slug}`, {
      ...storyblokApiOptions,
    });

    return localeStoryName(data.story);
  }

  return {
    all,
    find,
  };
};
